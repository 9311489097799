import {
  InputLabel,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styleInputPrimaryCenteredBold } from "../../theme";
import { options as ZAIDNumberValidatorOptions } from "../../utils/validators/ZAIDNumber/ZAIDNumber";
import { OPTIONS as InternationalPassportNumberOptions } from "../../utils/validators/common";
import { IdentificationNumber } from "../../services/core-api-adapter";

interface IdentificationNumberToggleProps {
  existingValue: IdentificationNumber;
  showInputLabels?: boolean;
  onIdentificationToggleChange: (_event: any, value: any) => void;
  isError?: boolean;
  errorText?: string;
}

export default function IdentificationNumberToggle({
  existingValue,
  showInputLabels = false,
  onIdentificationToggleChange,
  isError,
  errorText,
}: IdentificationNumberToggleProps) {
  const { t } = useTranslation();

  const [identificationObject, setIdentificationObject] = useState({
    identificationType: existingValue.identificationType || "IdNumber",
    identificationValue: existingValue.identificationValue || "",
  });

  function santiseIdentificationValue(value: string) {
    let sanitisedValue = "";

    if (identificationObject.identificationType === "IdNumber") {
      sanitisedValue = value.replace(/\D/g, "");
    } else {
      sanitisedValue = value;
    }

    return sanitisedValue;
  }

  const handleChange = (event: { target: { value: any } }) => {
    const { value } = event.target;
    setIdentificationObject({
      identificationType: identificationObject.identificationType,
      identificationValue: santiseIdentificationValue(value),
    });
  };

  useMemo(() => {
    onIdentificationToggleChange(
      identificationObject.identificationType,
      identificationObject.identificationValue
    );
  }, [identificationObject]);

  return (
    <Stack spacing={2}>
      <ToggleButtonGroup
        color="neutral"
        value={identificationObject.identificationType}
        onChange={(_event, type) => {
          if (type !== null) {
            setIdentificationObject({
              identificationType: type,
              identificationValue: "",
            });
          }
        }}
        fullWidth
        exclusive
      >
        <ToggleButton value="IdNumber">
          {t("CollectIdentificationNumber.options.IdNumber.toggleLabel")}
        </ToggleButton>
        <ToggleButton value="PassportNumber">
          {t("CollectIdentificationNumber.options.PassportNumber.toggleLabel")}
        </ToggleButton>
      </ToggleButtonGroup>

      <Stack>
        {identificationObject.identificationType === "IdNumber" ? (
          <Stack spacing={2}>
            {showInputLabels && (
              <InputLabel>
                <Typography
                  color="neutral.700"
                  variant="body2"
                  fontWeight={300}
                >
                  {t("common.IdNumber")}
                </Typography>
              </InputLabel>
            )}
            <TextField
              onChange={handleChange}
              value={identificationObject.identificationValue}
              error={isError}
              inputProps={{
                autoComplete: "off",
                inputMode: "numeric",
                maxLength: ZAIDNumberValidatorOptions.maxLength,
                "aria-label": t(
                  "CollectIdentificationNumber.options.IdNumber.inputLabel"
                ),
              }}
              sx={{
                input: {
                  ...styleInputPrimaryCenteredBold,
                },
              }}
            />
            {isError && errorText && (
              <Typography color="error" variant="body2">
                {errorText}
              </Typography>
            )}
          </Stack>
        ) : (
          <Stack spacing={2}>
            {showInputLabels && (
              <InputLabel>
                <Typography
                  color="neutral.700"
                  variant="body2"
                  fontWeight={300}
                >
                  {t("common.PassportNumber")}
                </Typography>
              </InputLabel>
            )}
            <TextField
              onChange={handleChange}
              value={identificationObject.identificationValue}
              inputProps={{
                autoComplete: "off",
                maxLength: InternationalPassportNumberOptions.maxLength,
                "aria-label": t(
                  "CollectIdentificationNumber.options.PassportNumber.inputLabel"
                ),
              }}
              sx={{
                input: {
                  ...styleInputPrimaryCenteredBold,
                },
              }}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
