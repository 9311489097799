import { SvgIcon, SvgIconProps } from "@mui/material";
import { lazy, Suspense, memo } from "react";

interface Props extends SvgIconProps {
  icon: string;
}

function IconComponent({ icon, ...props }: Props) {
  const DynamicIcon = lazy(
    async () => await import(`../../theme/icons/${icon}.tsx`)
  );

  return (
    <Suspense fallback={<SvgIcon sx={props?.sx} />}>
      <DynamicIcon {...props} />
    </Suspense>
  );
}

const IconLoader = memo(
  IconComponent,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps) === JSON.stringify(nextProps)
);
export default IconLoader;
