import { setup, assign, fromPromise } from "xstate";

import {
  loginUser,
  loginOTP,
  getMember,
  IdentityDocumentType,
  IdentityDocumentTypeValue,
  UserLoginRequestData,
  GetMemberSuccessResponseData,
} from "../../services/core-api-adapter";

export const eventNames = {
  GO_BACK: "GO_BACK",
  PHONE_NUMBER_COLLECTED: "PHONE_NUMBER_COLLECTED",
  COLLECT_UNUID: "COLLECT_UNUID",
  UNUID_COLLECTED: "UNUID_COLLECTED",
  ID_NUMBER_COLLECTED: "ID_NUMBER_COLLECTED",
  PASSPORT_NUMBER_COLLECTED: "PASSPORT_NUMBER_COLLECTED",
  OTP_COLLECTED: "OTP_COLLECTED",
  RESEND_OTP: "RESEND_OTP",
  COLLECT_ZA_ID_OR_INTERNATIONAL_PASSPORT:
    "COLLECT_ZA_ID_OR_INTERNATIONAL_PASSPORT",
  TRY_AGAIN: "TRY_AGAIN",
  SKIP_OTP_COLLECTION: "SKIP_OTP_COLLECTION",
};

interface Context {
  authenticationType: string;
  totalSteps: number;
  currentStepValue: number;
  isBackTransitionAllowed: boolean;
  phoneNumber: { globalSubscriberNumber: string; countryCode: string };
  identificationNumber: {
    identificationType: string;
    identificationValue: string;
  };
  UNUID: string;
  otp: string;
  loginResponse: {
    cognitoUserId: string;
    sessionId: string;
    authenticationResult: any;
  };
  loginResponseErrors: any[];
  loginOTPResponseErrors: any[];
  loginOTPResponse: any;
  getMemberResponse: GetMemberSuccessResponseData | null;
  getMemberError: any;
}

const initialContextValues: Context = {
  authenticationType: "",
  totalSteps: 4,
  currentStepValue: 1,
  isBackTransitionAllowed: true,
  phoneNumber: { globalSubscriberNumber: "", countryCode: "" },
  identificationNumber: {
    identificationType: "",
    identificationValue: "",
  },
  UNUID: "",
  otp: "",
  loginResponse: {
    cognitoUserId: "",
    sessionId: "",
    authenticationResult: null,
  },
  loginResponseErrors: [],
  loginOTPResponseErrors: [],
  loginOTPResponse: null,
  getMemberResponse: null,
  getMemberError: null,
};

export const authFlowMachine = setup({
  types: {
    context: {} as Context,
    events: {} as any,
  },
  actions: {
    addPhoneNumberToContext: assign(({ event }) => {
      if (event.type === eventNames.PHONE_NUMBER_COLLECTED) {
        return {
          phoneNumber: event.info,
        };
      }
      return {};
    }),

    addUNUIDValueToContext: assign(({ event }) => {
      if (event.type === eventNames.UNUID_COLLECTED) {
        return {
          UNUID: event.info,
          authenticationType: IdentityDocumentType.UNUID,
        };
      }
      return {};
    }),

    addIdNumberCredentialsToContext: assign(({ event }) => {
      if (event.type === eventNames.ID_NUMBER_COLLECTED) {
        return {
          identificationNumber: event.info,
          authenticationType: event.info.identificationType,
        };
      }
      return {};
    }),

    addPassportNumberCredentialsToContext: assign(({ event }) => {
      if (event.type === eventNames.PASSPORT_NUMBER_COLLECTED) {
        return {
          identificationNumber: event.info,
          authenticationType: event.info.identificationType,
        };
      }
      return {};
    }),

    addLoginResponseErrorsToContext: assign(({ event }) => {
      return {
        loginResponseErrors: [event.error.message].filter(
          (error) => error !== ""
        ),
      };
    }),

    removeLoginResponseErrorsFromContext: assign(() => {
      return {
        loginResponseErrors: [],
      };
    }),

    addLoginResponseToContext: assign(({ event }) => {
      return {
        loginResponse: {
          cognitoUserId: event.output.cognitoUserId,
          sessionId: event.output.sessionId,
          authenticationResult: event.output.authenticationResult,
        },
      };
    }),

    addOTPToContext: assign(({ event }) => {
      if (event.type === eventNames.OTP_COLLECTED) {
        return {
          otp: event.info,
        };
      }
      return {};
    }),

    addLoginOTPResponseErrorsToContext: assign(({ event }) => {
      return {
        loginOTPResponseErrors: [event.error.message],
      };
    }),

    removeLoginOTPResponseErrorsFromContext: assign(() => {
      return {
        loginOTPResponseErrors: [],
      };
    }),

    addLoginOTPResponseToContext: assign(({ event }) => {
      return {
        loginOTPResponse: event.output,
      };
    }),

    removeLoginOTPResponseFromContext: assign(() => {
      return {
        loginOTPResponse: null,
      };
    }),
  },
  actors: {
    fetchMember: fromPromise(async () => {
      return await getMember();
    }),

    loginUser: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        const { authenticationType } = input.context;

        const authData: UserLoginRequestData = {
          phoneNumber: input.context.phoneNumber,
          password: "",
          identityDocumentType: "",
        };

        if (authenticationType === IdentityDocumentType.IdNumber) {
          authData.password =
            input.context.identificationNumber.identificationValue;
          authData.identityDocumentType = IdentityDocumentTypeValue.ID_NUMBER;
        }

        if (authenticationType === IdentityDocumentType.PassportNumber) {
          authData.password =
            input.context.identificationNumber.identificationValue;
          authData.identityDocumentType =
            IdentityDocumentTypeValue.PASSPORT_NUMBER;
        }

        if (authenticationType === IdentityDocumentType.UNUID) {
          authData.password = input.context.UNUID;
          authData.identityDocumentType = IdentityDocumentTypeValue.UNUID;
        }

        return await loginUser(authData);
      }
    ),

    loginOTP: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        return await loginOTP({
          sessionID: input.context.loginResponse.sessionId,
          cognitoUserId: input.context.loginResponse.cognitoUserId,
          mfaCode: input.context.otp,
          phoneNumber: input.context.phoneNumber,
        });
      }
    ),
  },
}).createMachine({
  context: initialContextValues,
  id: "authFlow",
  initial: "authIdentificationTypeSelection",
  states: {
    authIdentificationTypeSelection: {
      on: {
        GO_BACK: {
          target: "exit",
        },
        COLLECT_ZA_ID_OR_INTERNATIONAL_PASSPORT: {
          target: "collectingZA_ID_OR_INTERNATIONAL_PASSPORT",
        },
        COLLECT_UNUID: {
          target: "collectingUNUID",
        },
      },
      always: {
        target: "collectingZA_ID_OR_INTERNATIONAL_PASSPORT",
      },
    },

    collectingZA_ID_OR_INTERNATIONAL_PASSPORT: {
      on: {
        GO_BACK: {
          target: "exit",
        },
        ID_NUMBER_COLLECTED: {
          target: "collectingPhoneNumber",
          actions: {
            type: "addIdNumberCredentialsToContext",
          },
        },
        PASSPORT_NUMBER_COLLECTED: {
          target: "collectingPhoneNumber",
          actions: {
            type: "addPassportNumberCredentialsToContext",
          },
        },
        COLLECT_UNUID: {
          target: "collectingUNUID",
        },
      },
      entry: ({ context: currentContext }) => assign({ ...currentContext }),
    },

    collectingUNUID: {
      entry: ({ context: currentContext }) => assign({ ...currentContext }),
      on: {
        GO_BACK: {
          target: "exit",
        },
        UNUID_COLLECTED: {
          target: "collectingPhoneNumber",
          actions: {
            type: "addUNUIDValueToContext",
          },
        },
        COLLECT_ZA_ID_OR_INTERNATIONAL_PASSPORT: {
          target: "collectingZA_ID_OR_INTERNATIONAL_PASSPORT",
        },
      },
    },

    collectingPhoneNumber: {
      entry: assign({ currentStepValue: 1, isBackTransitionAllowed: true }),
      on: {
        GO_BACK: {
          target: "authIdentificationTypeSelection",
        },
        PHONE_NUMBER_COLLECTED: {
          target: "verifyingIdentificationCredentials",
          actions: {
            type: "addPhoneNumberToContext",
          },
        },
      },
      exit: {
        type: "removeLoginResponseErrorsFromContext",
      },
    },

    verifyingIdentificationCredentials: {
      entry: assign({ isBackTransitionAllowed: false }),
      invoke: {
        id: "verifyingIdentificationCredentials",
        src: "loginUser",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "collectingOTP",
          actions: {
            type: "addLoginResponseToContext",
          },
        },
        onError: {
          target: "collectingPhoneNumber",
          actions: {
            type: "addLoginResponseErrorsToContext",
          },
        },
      },
    },

    collectingOTP: {
      on: {
        GO_BACK: {
          target: "collectingPhoneNumber",
        },
        OTP_COLLECTED: {
          target: "verifyingOTP",
          actions: {
            type: "addOTPToContext",
          },
        },
        RESEND_OTP: {
          target: "resendOTP",
        },
        SKIP_OTP_COLLECTION: {
          target: "gettingMember",
        },
      },
      entry: assign({ currentStepValue: 1, isBackTransitionAllowed: true }),
      exit: [
        {
          type: "removeLoginOTPResponseErrorsFromContext",
        },
        {
          type: "removeLoginResponseErrorsFromContext",
        },
      ],
    },

    verifyingOTP: {
      entry: {
        type: "removeLoginOTPResponseFromContext",
      },
      invoke: {
        id: "verifyingOTP",
        src: "loginOTP",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "gettingMember",
          actions: {
            type: "addLoginOTPResponseToContext",
          },
        },
        onError: {
          target: "collectingOTP",
          actions: {
            type: "addLoginOTPResponseErrorsToContext",
          },
        },
      },
    },

    resendOTP: {
      invoke: {
        id: "resendOTP",
        src: "loginUser",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "collectingOTP",
          actions: {
            type: "addLoginResponseToContext",
          },
        },
        onError: {
          target: "collectingOTP",
          actions: {
            type: "addLoginResponseErrorsToContext",
          },
        },
      },
    },

    gettingMember: {
      invoke: {
        id: "getMember",
        src: "fetchMember",
        onDone: {
          target: "done",
          actions: assign({
            getMemberResponse: ({ event }) => {
              return "cognitoUserId" in event.output ? event.output : null;
            },
          }),
        },
        onError: {
          target: "gettingMemberErrorGeneric",
        },
      },
    },

    gettingMemberErrorGeneric: {
      on: {
        TRY_AGAIN: {
          target: "authIdentificationTypeSelection",
        },
      },
    },

    exit: {
      type: "final",
    },

    done: {
      type: "final",
    },
  },
});
