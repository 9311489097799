import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Stack, Theme, Typography } from "@mui/material";
import { shadows } from "../../theme";
import IconLoader from "../IconLoader";

interface CircledBackButtonProps {
  label?: string;
  ariaLabel?: string;
  showLabel?: boolean;
  labelColor?: string;
  onClick?: Function;
}

export default function CircledBackButton(props: CircledBackButtonProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function onClick() {
    if (props.onClick) {
      props.onClick();
    } else {
      if (window.history.state && window.history.state.idx > 0) {
        navigate(-1);
      } else {
        navigate("/");
      }
    }
  }

  const label = props.label ? props.label : t("common.backButton");

  const button = (
    <Button
      onClick={onClick}
      color="info"
      aria-label={props.ariaLabel || label}
      sx={{
        borderRadius: "100%",
        boxShadow: shadows.light,
        minWidth: "unset",
        height: (theme: Theme) => theme.spacing(5),
        width: (theme: Theme) => theme.spacing(5),
      }}
    >
      <IconLoader icon="ArrowBackIcon" />
    </Button>
  );

  if (props.showLabel) {
    return (
      <Stack direction="row" spacing={2} alignItems="center">
        {button}
        <Typography color={props.labelColor} fontWeight={600} onClick={onClick}>
          {label}
        </Typography>
      </Stack>
    );
  } else {
    return button;
  }
}
