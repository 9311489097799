import React, { useState, useId, useEffect } from "react";
import { Button, Stack, Typography, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import FaceIconBrandLarge from "../../theme/icons/FaceIconBrandLarge";
import InternationalPhoneNumberInput, {
  InternationalPhoneNumber,
} from "../InternationalPhoneNumberInput";
import { useGlobalStore } from "../../store";
import { GetHelpFABConfig } from "../../theme";
import GetHelpCTAButton from "../GetHelpCTAButton";
import {
  AnalyticsEvent,
  trackFormEvent,
} from "../../services/analytics-adapter";

interface CollectPhoneNumberProps {
  onSubmit: (phoneNumber: InternationalPhoneNumber) => void;
  value?: { globalSubscriberNumber: string; countryCode: string };
}

export default function CollectPhoneNumber(props: CollectPhoneNumberProps) {
  const { t } = useTranslation();
  const inputID = useId();
  const { state } = useGlobalStore();
  const defaultCountryCode = "ZA";
  const [phoneNumber, setPhoneNumber] = useState<InternationalPhoneNumber>({
    countryCode: state.countryCode || defaultCountryCode,
    globalSubscriberNumber: props.value?.globalSubscriberNumber || "",
  });

  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);

  useEffect(() => {
    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Enter cellphone number",
    });
  }, []);

  function onChange(phoneNumber: InternationalPhoneNumber, isValid: boolean) {
    setIsSubmitButtonEnabled(isValid);
    setPhoneNumber(phoneNumber);
  }

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    props.onSubmit(phoneNumber);
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing={2} justifyContent="flex-end" sx={{ pb: 2 }}>
          <FaceIconBrandLarge />
          <Stack spacing={2}>
            <Typography component="h2" variant="h3">
              {t("CollectPhoneNumber.CellphoneNumberInputDescription")}
            </Typography>
          </Stack>
          <Stack pb={4}>
            {(
              t<any, any, string[]>(
                "CollectPhoneNumber.CellphoneNumberInputSubtitle",
                {
                  returnObjects: true,
                }
              ) as []
            ).map((text: string, index: number) => (
              <Typography key={String(text + index)} variant="body1">
                {text}
              </Typography>
            ))}
          </Stack>

          <InputLabel htmlFor={inputID}>
            {t("CollectPhoneNumber.CellphoneNumberInputLabel")}
          </InputLabel>
          <InternationalPhoneNumberInput
            value={phoneNumber}
            onChange={onChange}
          />
          <Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignSelf="flex-end"
              position="relative"
              width={GetHelpFABConfig.floatingActionButtonWidth}
              py={1}
            >
              <GetHelpCTAButton eventName="action.contactSupportFab" />
            </Stack>

            <Button
              type="submit"
              color="primary"
              disabled={!isSubmitButtonEnabled}
              aria-label={t("common.nextButton")}
              fullWidth
            >
              {t("common.nextButton")}
            </Button>
          </Stack>
        </Stack>
      </form>
    </>
  );
}
