import * as Sentry from "@sentry/react";

export function initialiseApplicationMonitoring() {
  if (import.meta.env.VITE_APP_SENTRY_DSN?.length) {
    try {
      Sentry.init({
        dsn: import.meta.env.VITE_APP_SENTRY_DSN,
        environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: Number(
          import.meta.env.VITE_APP_SENTRY_TRACES_SAMPLE_RATE
        ),
      });
    } catch (error) {}
  }
}

export function captureException(err: any) {
  try {
    Sentry.captureException(err);
  } catch (error) {}
}
