import { ChangeEvent, FormEvent, useState, useEffect } from "react";
import { Button, TextField, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FaceIconBrandLarge from "../../theme/icons/FaceIconBrandLarge";
import {
  isValid as isValidUNUID,
  OPTIONS as UNUIDValidatorOptions,
} from "../../utils/validators/common";
import { convertPxToRem } from "../../utils";
import SwitchAuthIdentificationTypeModal, {
  ModalKeys,
} from "../SwitchAuthIdentificationTypeModal";
import GetHelpCTAButton from "../GetHelpCTAButton";
import { GetHelpFABConfig } from "../../theme";
import {
  AnalyticsEvent,
  trackFormEvent,
} from "../../services/analytics-adapter";

interface CollectUNUIDProps {
  value: string;
  onSubmit: Function;
  onSwitchAuthTypeHandler?: Function;
}

export default function CollectUNUID(props: CollectUNUIDProps) {
  const { t } = useTranslation();
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const [UNUIDValue, setUNUIDValue] = useState(props.value || "");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    updateNextButtonDisabledState();
  }, [UNUIDValue]);

  useEffect(() => {
    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Enter UNU ID",
    });
  }, []);

  function isValid(identificationValue: string) {
    if (identificationValue) {
      return isValidUNUID(identificationValue);
    }
    return false;
  }

  function updateNextButtonDisabledState() {
    setIsNextButtonEnabled(isValid(UNUIDValue));
  }

  function onUNUIDInput(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setUNUIDValue(value);
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    props.onSubmit(UNUIDValue);
  }

  function onModalClose() {
    setIsModalOpen(false);
  }

  function onSwitchAuthTypeHandler() {
    if (props.onSwitchAuthTypeHandler) {
      props.onSwitchAuthTypeHandler();
    }

    setIsModalOpen(false);
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing={6} justifyContent="space-between" sx={{ pb: 2 }}>
          <Stack spacing={2}>
            <FaceIconBrandLarge />
            <Typography variant="h3">{t("CollectUNUID.title")}</Typography>
          </Stack>

          <Stack sx={{ mb: 4, mt: 4 }}>
            <Typography variant="body1">
              {t("CollectUNUID.inputLabel")}
            </Typography>
            <TextField
              onInput={onUNUIDInput}
              value={UNUIDValue}
              inputProps={{
                autoComplete: "off",
                inputMode: "text",
                maxLength: UNUIDValidatorOptions.maxLength,
                "aria-label": t("CollectUNUID.inputLabel"),
              }}
              placeholder={t("CollectUNUID.inputPlaceholder")}
              sx={{ input: { fontSize: convertPxToRem(16) } }}
            />

            <Button
              sx={{ mt: 2, textAlign: "center" }}
              onClick={() => setIsModalOpen(true)}
              variant="text"
            >
              {t("CollectUNUID.switchToRSAIDLink")}
            </Button>
          </Stack>
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignSelf="flex-end"
              position="relative"
              width={GetHelpFABConfig.floatingActionButtonWidth}
              pt={1}
            >
              <GetHelpCTAButton eventName="action.contactSupportFab" />
            </Stack>

            <Button
              type="submit"
              color="primary"
              disabled={!isNextButtonEnabled}
              aria-label={t("common.nextButton")}
            >
              {t("common.nextButton")}
            </Button>
          </Stack>
        </Stack>
      </form>
      <SwitchAuthIdentificationTypeModal
        extras={{
          onRatherSignInWithDifferentAuthType: onSwitchAuthTypeHandler,
          modalLabelKey: ModalKeys.WHAT_IS_AN_UNUID,
          authTypeLabel: ModalKeys.RSA_ID_OR_PASSPORT,
        }}
        isOpen={isModalOpen}
        onClose={onModalClose}
      />
    </>
  );
}
