import { detect } from "detect-browser";
import { AppDistributionIdentifier } from "../store";

export function maskCellPhoneNumber(value: string) {
  const keepDigitsCount = 4;
  const valueAsOnlyDigits = value.replace(/\D/g, "");
  return valueAsOnlyDigits
    .split("")
    .map((digit: string, index: number) => {
      return index + keepDigitsCount >= valueAsOnlyDigits.length ? digit : "*";
    })
    .join("");
}

export function normaliseStringToNumber(value: string): string {
  return value.replace(/\D/g, "");
}

export function normaliseStringToNumberWithPeriod(value: string): string {
  const onlyNumbersAndDots = value.replace(/[^0-9.]/g, "");
  const valueSplitOnPeriod = onlyNumbersAndDots.split(".");
  let result = valueSplitOnPeriod.slice(0, 2).join(".");
  if (result.indexOf(".") === 0) {
    result = "0" + result;
  }
  return result;
}

export function formatAddressForTextArea(address: any): string {
  return Object.keys(address)
    .map((key) => (address[key] ? address[key] : ""))
    .join("\n");
}

export function clampNumber(value: number, min: number, max: number) {
  return Math.max(min, Math.min(value, max));
}

export function clampDate(value: Date, min: Date, max: Date): Date {
  const minValueAsTime = min.getTime();
  const maxValueAsTime = max.getTime();
  const valueAsTime = value.getTime();
  return new Date(clampNumber(valueAsTime, minValueAsTime, maxValueAsTime));
}

export function calculateBMI(height: number, weight: number) {
  const result = weight / Math.pow(height / 100, 2);
  return isFinite(result) ? result : 0;
}

export function convertBooleanLikeStringToBoolean(value: string | undefined) {
  if (typeof value !== "string") {
    return null;
  }

  const preparedValue = value?.toLowerCase();
  if (preparedValue === "true" || preparedValue === "yes") {
    return true;
  } else if (preparedValue === "false" || preparedValue === "no") {
    return false;
  }
  return null;
}

export function convertLength(
  fromUnit: string,
  toUnit: string,
  value: number
): number {
  const unitConversionPair = `${fromUnit}, ${toUnit}`;

  switch (unitConversionPair) {
    case "CM, M":
      return value / 100;
    case "M, CM":
      return value * 100;
    case "CM, IN":
      return value / 2.54;
    case "IN, CM":
      return value * 2.54;
    case "M, IN":
      return value * 39.37;
    case "IN, M":
      return value / 39.37;
    default:
      return value;
  }
}

export function convertStringToJSON(jsonString: string) {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return {};
  }
}

export function convertPascalCaseToWords(value: string) {
  return value
    .replace(/([A-Z]+)/g, " $1")
    .replace(/([A-Z][a-z])/g, " $1")
    .split("  ")
    .join(" ")
    .trim();
}

export function convertSnakeCaseToSentenceCase(value: string) {
  let sentence = value.toLowerCase().replace(/_/g, " ");
  sentence = sentence.charAt(0).toUpperCase() + sentence.slice(1);
  return sentence;
}

export function convertPascalCaseToSnakeCase(value: string) {
  return convertPascalCaseToWords(value).split(" ").join("_").toLowerCase();
}

export function isOlderThanSixMonths(date: Date): boolean {
  const dateAsTime = date.getTime();
  const halfAYearInDays = 182.5;
  const currentDateAsTime = new Date().getTime();
  const timeDeltaAsSeconds = (currentDateAsTime - dateAsTime) / 1000;
  return timeDeltaAsSeconds / (60 * 60 * 24) > halfAYearInDays;
}

export function formatDateToReadableString(date: Date): string {
  if (isNaN(date.getTime())) {
    return "";
  }

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "long",
    year: "numeric",
  };
  return date.toLocaleDateString("en-GB", options);
}

export function isDateYesterday(date: Date): boolean {
  const now = new Date();

  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);

  const isYesterday =
    date.getFullYear() === yesterday.getFullYear() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getDate() === yesterday.getDate();

  return isYesterday;
}

export function getNumberOfDaysSince(date: Date): number {
  const dateAsTime = date.getTime();
  const currentDateAsTime = new Date().getTime();
  const timeDeltaAsSeconds = (currentDateAsTime - dateAsTime) / 1000;
  return timeDeltaAsSeconds / (60 * 60 * 24);
}

export function getDiffBetweenDatesInHours(date1: Date, date2: Date) {
  let diff = (date1.getTime() - date2.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.abs(Math.round(diff));
}

export function convertPxToRem(px: number): string {
  return `${px / 16}rem`;
}

export function getDefaultFontSize() {
  const element = document.createElement("div");
  element.style.width = "1rem";
  element.style.display = "none";
  document.body.append(element);

  const widthMatch = window
    .getComputedStyle(element)
    .getPropertyValue("width")
    .match(/\d+/);

  element.remove();

  if (!widthMatch || widthMatch.length < 1) {
    return null;
  }

  const result = Number(widthMatch[0]);
  return !isNaN(result) ? result : null;
}

export function isObjectEmpty(objectName: any) {
  return Boolean(
    objectName &&
      Object.keys(objectName).length === 0 &&
      objectName.constructor === Object
  );
}

export function getAppDistributionIdentifier(
  url: string | null | undefined
): AppDistributionIdentifier {
  let appDistributionIdentifier: AppDistributionIdentifier =
    AppDistributionIdentifier.WEB;

  if (url) {
    const urlParts = url.split("#");
    const keyValuePair = urlParts.find((pair) =>
      pair.startsWith("distribution=")
    );

    if (keyValuePair) {
      const appDistributionIdentifierFromURL = keyValuePair?.split("=")[1];

      if (appDistributionIdentifierFromURL in AppDistributionIdentifier) {
        appDistributionIdentifier =
          AppDistributionIdentifier[
            appDistributionIdentifierFromURL as AppDistributionIdentifier
          ];
      } else {
        appDistributionIdentifier = AppDistributionIdentifier.WEB;
      }
    }
  }

  const isAppInStandaloneMode = window?.matchMedia(
    "(display-mode: standalone)"
  )?.matches;

  if (isAppInStandaloneMode) {
    appDistributionIdentifier = AppDistributionIdentifier.PWA;
  }

  return appDistributionIdentifier;
}

export function getUserAgent() {
  return window.navigator.userAgent;
}

export function buildWhatsAppUniversalLinkURL(
  phoneNumber: string,
  text?: string
) {
  let url = "https://wa.me/" + phoneNumber.replace(/\D/g, "");

  if (text) {
    url = `${url}?text=${encodeURI(text)}`;
  }

  return url;
}

export function buildMapLinkURL(query: string) {
  const device = detect();
  const deviceOS = device?.os;

  if (deviceOS === "iOS" || deviceOS === "Mac OS") {
    return `maps://maps.apple.com/?q=${query}`;
  }
  return `https://maps.google.com/maps?q=${query}`;
}

export function replaceSubjectWithString(
  originalString: string,
  subject: string,
  stringToReplaceWith: string
) {
  return originalString.replace(subject, stringToReplaceWith);
}

export function isStringDefined(
  stringToCheck: string | null | undefined
): boolean {
  return (
    stringToCheck !== null &&
    stringToCheck !== undefined &&
    stringToCheck !== ""
  );
}

export function isMobileDevice(): boolean {
  const device = detect();
  const deviceOS = device?.os;
  if (
    deviceOS !== "iOS" &&
    deviceOS !== "Android OS" &&
    deviceOS !== "Windows Mobile" &&
    deviceOS !== "BlackBerry OS"
  ) {
    return false;
  }
  return true;
}

export function getCurrentFormattedTime(): string {
  const now = new Date();
  const currentTime = `${String(now.getHours()).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}:${String(now.getSeconds()).padStart(2, "0")}`;
  return currentTime;
}

export function getCurrentFormattedDay(): string {
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const now = new Date();
  const dayIndex = now.getDay();
  const formattedDay = weekdays[dayIndex].toUpperCase();
  return formattedDay;
}

export function isFaceScanCompatibleDevice(): {
  isIOSWithNonSafariBrowser: boolean;
  isAndroidWithNonChromeOrSamsungBrowser: boolean;
} {
  const device = detect();
  const deviceBrowser = device?.name || "unknown";
  const deviceOsNormalised =
    device?.os?.toUpperCase().split(" ").join("_") || "";

  const isIOSWithNonSafariBrowser =
    deviceOsNormalised === "IOS" && deviceBrowser !== "ios";
  const isAndroidWithNonChromeOrSamsungBrowser =
    deviceOsNormalised === "ANDROID_OS" &&
    !(deviceBrowser === "chrome" || deviceBrowser === "samsung");

  return { isIOSWithNonSafariBrowser, isAndroidWithNonChromeOrSamsungBrowser };
}

export function getDateOfBirthFromZAIdNumber(value: string) {
  const dateDigits = value.slice(0, 6);
  const currentYear = new Date().getFullYear() % 100;
  const yearPrefix =
    parseInt(dateDigits.slice(0, 2)) <= currentYear ? "20" : "19";
  const year = yearPrefix + dateDigits.slice(0, 2);
  const month = dateDigits.slice(2, 4);
  const day = dateDigits.slice(4, 6);
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}
