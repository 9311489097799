import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack } from "@mui/material";
import { styleInputDefault, styleInputPrimaryCenteredBold } from "../../theme";
import { clampNumber, convertPxToRem } from "../../utils";

interface OTPInputProps {
  inputPattern: string;
  onChange?: Function;
  clearInputValue?: boolean;
}

interface InputDisplayBoxProps {
  absoluteIndex: number;
  inputCaretPosition: number;
  pinLength: number;
  isFocused: boolean;
  inputValue: string;
}

interface GenericSeparatorProps {
  character: string;
  index: number;
}

const InputDisplayBox: React.FC<InputDisplayBoxProps> = ({
  absoluteIndex,
  inputCaretPosition,
  pinLength,
  isFocused,
  inputValue,
}) => {
  const clampedInputCaretPosition = clampNumber(
    inputCaretPosition,
    0,
    pinLength - 1
  );
  const classNameList = [
    "OTPInput__InputDisplayBox",
    ...(absoluteIndex === clampedInputCaretPosition ? ["active"] : []),
    ...(isFocused === true ? ["is-focused"] : []),
  ];
  return (
    <Typography
      component="span"
      className={classNameList.join(" ")}
      sx={{
        ...styleInputDefault,
        ...styleInputPrimaryCenteredBold,
        alignItems: "center",
        border: 1,
        borderRadius: 2,
        borderColor: "neutral.300",
        display: "flex",
        justifyContent: "center",
        minHeight: (theme) => theme.spacing(7),
        width: (theme) => theme.spacing(5),
        "&.is-focused.active": {
          border: 2,
          borderColor: "primary.main",
        },
      }}
    >
      {inputValue[absoluteIndex] || ""}
    </Typography>
  );
};

const GenericSeparator: React.FC<GenericSeparatorProps> = ({ character }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: (theme) => theme.spacing(2),
        minHeight: (theme) => theme.spacing(7),
      }}
    >
      <Typography
        component="span"
        sx={{
          fontSize: convertPxToRem(34),
          color: (theme) => theme.palette.neutral[900],
        }}
      >
        {character}
      </Typography>
    </Box>
  );
};

const OTPInput: React.FC<OTPInputProps> = ({
  onChange,
  clearInputValue = false,
  inputPattern,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [inputCaretPosition, setInputCaretPosition] = useState(0);
  const inputElement = useRef<HTMLInputElement | null>(null);

  const pinLength = inputPattern
    .split("")
    .filter((character) => character === "*").length;

  useEffect(() => {
    if (clearInputValue) {
      setInputValue("");
    }
  }, [clearInputValue]);

  useEffect(() => {
    if (onChange) {
      onChange(inputValue);
    }
  }, [inputValue]);

  function onKeyUp(event: React.KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const caretPosition = target.selectionStart ? target.selectionStart : 0;
    setInputCaretPosition(caretPosition);
  }

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setInputValue(sanitiseValue(value));
  }

  function onInputFocus() {
    setIsFocused(true);
    if (inputElement.current) {
      inputElement.current.setSelectionRange(
        inputValue.length,
        inputValue.length
      );
    }
  }

  function onInputBlur() {
    setIsFocused(false);
  }

  function sanitiseValue(value: string): string {
    return value.replace(/\D/g, "").substring(0, pinLength);
  }

  function renderInputValueOutput() {
    let specialCharactersCount = 0;

    return inputPattern.split("").map((character: string, index: number) => {
      const key = index.toString();

      if (character === "*") {
        return (
          <InputDisplayBox
            key={key}
            inputCaretPosition={inputCaretPosition}
            inputValue={inputValue}
            isFocused={isFocused}
            pinLength={pinLength}
            absoluteIndex={index - specialCharactersCount}
          />
        );
      } else {
        specialCharactersCount += 1;

        return (
          <GenericSeparator key={key} character={character} index={index} />
        );
      }
    });
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Stack
        aria-label={t("OTPInput.inputDisplayLabel")}
        direction="row"
        justifyContent="space-between"
      >
        {renderInputValueOutput()}
      </Stack>
      <input
        ref={inputElement}
        autoComplete="off"
        onChange={onInputChange}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        onKeyUp={onKeyUp}
        inputMode="numeric"
        value={inputValue}
        type="text"
        aria-label={t("OTPInput.label")}
        style={{
          position: "absolute",
          appearance: "none",
          height: "100%",
          left: 0,
          top: 0,
          opacity: 0,
          width: "100%",
          zIndex: 1,
        }}
      />
    </Box>
  );
};

export default OTPInput;
