import { Button, Link, Stack, Typography } from "@mui/material";
import { FormEvent, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IdentificationNumber } from "../../services/core-api-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "../../services/feature-toggle-adapter";
import { GetHelpFABConfig } from "../../theme";
import FaceIconBrandLarge from "../../theme/icons/FaceIconBrandLarge";
import { isValid as isValidZAIDNumber } from "../../utils/validators/ZAIDNumber/ZAIDNumber";
import { isValid as isValidInternationalPassportNumber } from "../../utils/validators/common";
import GetHelpCTAButton from "../GetHelpCTAButton";
import IdentificationNumberToggle from "../IdentificationNumberToggle";
import SwitchAuthIdentificationTypeModal from "../SwitchAuthIdentificationTypeModal";
import {
  AnalyticsEvent,
  trackFormEvent,
} from "../../services/analytics-adapter";

interface CollectIdentificationNumberProps {
  value: IdentificationNumber;
  onSubmit: Function;
  onSwitchAuthTypeHandler?: Function;
}

export default function CollectIdentificationNumber(
  props: CollectIdentificationNumberProps
) {
  const { t } = useTranslation();
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const defaultIdentificationType = "IdNumber";
  const [identificationType, setIdentificationType] = useState(
    props.value.identificationType || defaultIdentificationType
  );
  const [identificationValue, setIdentificationValue] = useState(
    props.value.identificationValue || ""
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Enter ID Number or Passport",
    });
  }, []);

  useEffect(() => {
    updateNextButtonDisabledState();
  }, [identificationType, identificationValue]);

  function isValid(_identificationType: string, _identificationValue: string) {
    switch (_identificationType) {
      case "IdNumber":
        return isValidZAIDNumber(_identificationValue);
      case "PassportNumber":
        return isValidInternationalPassportNumber(_identificationValue);
    }
    return false;
  }

  function updateNextButtonDisabledState() {
    setIsNextButtonEnabled(isValid(identificationType, identificationValue));
  }

  const handleIdentificationToggleChange = (
    type: SetStateAction<string>,
    value: SetStateAction<string>
  ) => {
    setIdentificationType(type);
    setIdentificationValue(value);
  };

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    props.onSubmit(identificationType, identificationValue);
  }

  function onModalClose() {
    setIsModalOpen(false);
  }

  function onSwitchAuthTypeHandler() {
    if (props.onSwitchAuthTypeHandler) {
      props.onSwitchAuthTypeHandler();
    }

    setIsModalOpen(false);
  }
  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing={2} justifyContent="space-between" sx={{ pb: 2 }}>
          <FaceIconBrandLarge />
          <Stack spacing={2}>
            <Typography component="h2" variant="h3">
              {t(
                `CollectIdentificationNumber.options.${identificationType}.title` as any
              )}
            </Typography>

            <Typography py={2} variant="body1">
              {t(
                `CollectIdentificationNumber.options.${identificationType}.subtitle` as any
              )}
            </Typography>
          </Stack>
          <Stack>
            <IdentificationNumberToggle
              existingValue={props.value}
              onIdentificationToggleChange={handleIdentificationToggleChange}
            />
            {isFeatureEnabled(
              FeatureToggleIdentifier.ENABLE_OPTIMISE_SIGN_IN
            ) === false && (
              <>
                {isFeatureEnabled(
                  FeatureToggleIdentifier.ENABLE_AUTH_IDENTIFICATION_TYPE_SELECTION
                ) && (
                  <Button
                    sx={{ textAlign: "center" }}
                    onClick={() => setIsModalOpen(true)}
                    variant="text"
                    component={Link}
                  >
                    {t("CollectIdentificationNumber.switchToUNUIDLink")}
                  </Button>
                )}
              </>
            )}
          </Stack>

          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignSelf="flex-end"
              position="relative"
              width={GetHelpFABConfig.floatingActionButtonWidth}
            >
              <GetHelpCTAButton eventName="action.contactSupportFab" />
            </Stack>

            <Button
              type="submit"
              color="primary"
              disabled={!isNextButtonEnabled}
              aria-label={t("common.nextButton")}
            >
              {t("common.nextButton")}
            </Button>
          </Stack>
          <Stack>
            {isFeatureEnabled(
              FeatureToggleIdentifier.ENABLE_OPTIMISE_SIGN_IN
            ) && (
              <Button
                sx={{ textAlign: "center" }}
                onClick={onSwitchAuthTypeHandler}
                variant="text"
                component={Link}
              >
                {t("CollectIdentificationNumber.signInWithUNUID")}
              </Button>
            )}
          </Stack>
        </Stack>
      </form>
      <SwitchAuthIdentificationTypeModal
        extras={{
          onRatherSignInWithDifferentAuthType: onSwitchAuthTypeHandler,
          modalLabelKey: "CollectIdentificationNumberModal",
          authTypeLabel: "UNUID",
        }}
        isOpen={isModalOpen}
        onClose={onModalClose}
      />
    </>
  );
}
