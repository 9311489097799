import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultDialog, {
  DefaultDialogImplementationProps,
} from "../DefaultDialog";
import { trackEvent } from "../../services/analytics-adapter";
import { getConfigurationVariable } from "../../services/configuration-adapter";

export default function NoOTPReceivedModal({
  isOpen,
  onClose,
  extras = {},
}: DefaultDialogImplementationProps) {
  const { t } = useTranslation();

  function onGetSupportLinkClick() {
    trackEvent({ event: "action.supportRequested", source: "no OTP received" });
  }

  return (
    <DefaultDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("NoOTPReceivedModal.title")}
      actionButtons={
        <>
          <Button size="small" onClick={extras.resendOTP}>
            {t("NoOTPReceivedModal.resendOTPButton")}
          </Button>
          <Button
            component="a"
            href={getConfigurationVariable("VITE_APP_UNU_HEALTH_SUPPORT_URI")}
            onClick={onGetSupportLinkClick}
            target="_blank"
            variant="text"
            size="small"
          >
            {t("ReportAnIssue.reportAnIssueButton")}
          </Button>
        </>
      }
    >
      <Typography align="center">
        {t("NoOTPReceivedModal.body", {
          phoneNumber: extras.phoneNumber,
        })}
      </Typography>
    </DefaultDialog>
  );
}
