import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { trackEvent } from "../../services/analytics-adapter";
import IconLoader from "../IconLoader";
import { GetHelpFABConfig } from "../../theme";

interface GetHelpCTAButtonProps {
  buttonColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  iconColor?:
    | "action"
    | "disabled"
    | "primary"
    | "inherit"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  eventName?: string;
  eventSource?: string;
  onClick?: Function;
}

export default function GetHelpCTAButton({
  buttonColor,
  iconColor,
  eventName,
  eventSource,
  onClick,
}: GetHelpCTAButtonProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  function onGetHelpCTAButtonClick() {
    trackEvent({
      event: eventName || "action.getHelpInitiated",
      "getHelpInitiated.source": eventSource || "get help fab",
    });

    navigate("/get-help", {
      state: {
        from: location,
        eventName,
        eventSource: eventSource || window.location.pathname,
      },
    });

    if (onClick) {
      onClick();
    }
  }

  return (
    <Button
      onClick={onGetHelpCTAButtonClick}
      aria-label={t("GetHelpCTAButton.label")}
      color={buttonColor}
      fullWidth
      sx={{
        borderRadius: GetHelpFABConfig.floatingActionButtonHeight,
        boxShadow: 18,
        minWidth: GetHelpFABConfig.floatingActionButtonWidth,
        minHeight: GetHelpFABConfig.floatingActionButtonHeight,
        p: 0,
      }}
    >
      <IconLoader
        icon="QuestionMarkIcon"
        sx={{ margin: "auto" }}
        color={iconColor}
      />
    </Button>
  );
}
