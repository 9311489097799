import { PropsWithChildren } from "react";
import { Box } from "@mui/material";
import { maxWidthConfig, maxWidthContainerStyling } from "../../theme";

interface MaxWidthContainerProps extends PropsWithChildren {
  maxWidth?: string;
}

export default function MaxWidthContainer({
  maxWidth = maxWidthConfig.defaultMaxWidth,
  children,
}: MaxWidthContainerProps) {
  return (
    <Box
      sx={{
        ...maxWidthContainerStyling,
        maxWidth: maxWidth,
      }}
    >
      {children}
    </Box>
  );
}
